<template>
  <div class="mt-6">
    <table class="min-w-full divide-y divide-gray-200">
      <thead class="bg-gray-50">
        <tr>
          <th
            scope="col"
            class="
              px-6
              py-3
              text-left text-xs
              font-medium
              text-gray-500
              uppercase
              tracking-wider
            "
          >
            STAFF
          </th>
          <th
            scope="col"
            class="
              px-6
              py-3
              text-center text-xs
              font-medium
              text-gray-500
              uppercase
              tracking-wider
            "
          >
            DATE
          </th>
        </tr>
      </thead>

      <tbody v-if="data.length >= 1" class="bg-white divide-y divide-gray-200">
        <tr v-for="(transaction, i) in data" :key="i">
          <td class="px-6 py-4 whitespace-nowrap">
            <div class="flex items-center">
              <div class="ml-4">
                <router-link
                  :to="`/staffs/${transaction.staff_id}`"
                  class="text-sm font-medium text-gray-900"
                >
                  {{ transaction.staff_name }}
                  <p class="text-gray-500 text-xs">{{ transaction.branch }}</p>
                </router-link>
              </div>
            </div>
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-center">
            <div class="text-sm text-gray-900">
              {{ transaction.created_at | moment("dddd, MMMM Do YYYY") }}
            </div>
          </td>
        </tr>

        <!-- More people... -->
      </tbody>

      <tbody class="flex justify-center w-full" v-else>
        <tr class="py-4 text-gray-500" col="2">
          No Pending Worksheet found
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ["data", "showHeader"],

  data: () => ({
    downloadLoading: false,

    filename: "",
    autoWidth: true,
    bookType: "xlsx",
  }),

  computed: {},

  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    getFileName() {
      return (
        this.$route.params.reportOn +
        "-" +
        this.$route.params.from +
        "-" +
        this.$route.params.to
      );
    },

    handleDownload() {
      this.downloadLoading = true;
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = [
          "Staff",
          "Transactions",
          "Amount",
          "Commission",
          "Total",
        ];
        const filterVal = [
          "staff",
          "transactions",
          "amount",
          "commission",
          "total",
        ];
        const list = this.data;
        console.log("LIST", list);
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.getFileName(),
          autoWidth: this.autoWidth,
          bookType: this.bookType,
        });
        this.downloadLoading = false;
      });
    },

    formatJson(filterVal, jsonData) {
      return Object.keys(jsonData).map((v) => {
        console.log();
        return filterVal.map((j) => {
          console.log("J", j);
          console.log();

          if (j === "staff") {
            return v;
          } else if (j === "total") {
            return (
              Number(jsonData[v]["amount"]) + Number(jsonData[v]["commission"])
            );
          } else {
            return jsonData[v][j];
          }
        });
      });
    },
  },
};
</script>
